import React from 'react';
import { Link } from 'react-router-dom';


export default function App() {

    return (

        <footer>&copy; 2021-2077 Ellison Steele >>> <Link to="admin-login"> ADMIN LOGIN</Link></footer>

    )
}

