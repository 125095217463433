import React, { useState } from 'react';

function AdminLogin({ history }) {

    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });

    const handleInputChange = (key, val) => {
        setCredentials({ ...credentials, [key]: val });
    };

    const validate = (e) => {
        e.preventDefault();
        if (credentials.email == "" || credentials.password == "") {
            alert("Please fill in the required fields!");
            return;
        }
        fetch("/auth", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(credentials),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    console.log(response)
                    if (response.token) {
                        localStorage.setItem('access_token', response.token)
                        alert(`${credentials.email} is now logged in.`);
                        history.push('/contact-entries')
                    } else {
                        alert(response.message);
                    }
                }
            });
    };

    return (
        <div className="App">

            <div className="container">

                <div className="formContainer">
                    <form>

                        <legend>ADMIN LOGIN</legend>

                        <div className="formSubcontainer">
                            <label for="email">Email</label>
                            <div>
                                <input
                                    value={credentials.email}
                                    onChange={(e) => handleInputChange("email", e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                />
                            </div>
                        </div>


                        <div className="formSubcontainer">
                            <label for="password">Password</label>
                            <div>
                                <input
                                    value={credentials.password}
                                    onChange={(e) => handleInputChange("password", e.target.value)}
                                    id="password"
                                    name="password"
                                    type="text"
                                    required
                                />
                            </div>
                        </div>

                        <div className="formSubcontainer">
                            <label for="submit"></label>
                            <div>
                                <button
                                    id="submit"
                                    name="submit"
                                    onClick={validate}
                                >
                                    Log In
                                </button>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    );
}

export default AdminLogin;
