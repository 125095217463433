import React, { useState, useEffect } from 'react';

const Contact = (props) => (
    <div>
        <p>Name: {props.data.name}</p>
        <p>Email: {props.data.email}</p>
        <p>Phone Number: {props.data.phoneNumber}</p>
        <p>Reason for Contact: {props.data.reason}</p>
        <p>Comments: {props.data.content}</p>
        <br />
    </div>
)

function ContactEntries({ history }) {
    const [contacts, setContacts] = useState([])

    useEffect(() => {
        const access_token = localStorage.getItem("access_token");
        fetch("/contact_form/entries", {
            headers: {
                "authorization": `Bearer ${access_token}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setContacts(response)
            });
    }, []);

    const logOut = () => {
        localStorage.removeItem('access_token');
        alert("Logged out!");
        history.push('/');
    }

    return (
        <div className="App">

            <div className="container">

                <div className="entriesContainer">
                    {
                        contacts.map(contact => (
                            <Contact key={contact.id} data={contact} />
                        ))
                    }
                </div>
                <button
                    id="submit"
                    name="submit"
                    onClick={logOut}
                >
                    Log Out
                </button>

            </div>
        </div>
    );
}

export default ContactEntries;
