import React, { useState } from 'react';


function Contact() {
    const [contactData, setContactData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        reason: "",
        content: ""
    });

    const handleInputChange = (key, val) => {
        setContactData({ ...contactData, [key]: val });
        //console.log(contactData)
    };

    const validate = (e) => {
        e.preventDefault();

        let valid = true;

        if (!contactData.name) {
            alert("Please enter your name.");
        }
        /* will make sure the email is formatted as blank@blank.com*/
        else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(contactData.email))) {
            alert("Please enter a valid email.");
        }
        /*this one makes sure the phone number has enough digits and is formatted right*/
        else if (!(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(contactData.phoneNumber))) {
            alert("Please enter a valid phone number.");
        }

        else if (!contactData.reason) {
            alert("Please select the reason for contact.");
        }

        else if (!contactData.content) {
            alert("Please enter your comments.");
        }
        else {
            fetch("/contact_form/entries", {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(contactData),
            }).then((response) => {
                if (response) {
                    if (response.status === 201) {
                        alert("Thank you for contacting us, we will respond to you shortly.");
                    } else {
                        alert(`${response.status}, ya dun goofed.`);
                    }
                }
            });

        }
    }

    return (

        <div className="contact">

            <div class="container">


                <div class="formContainer">
                    <form>

                        <legend>CONTACT US</legend>


                        <div class="formSubcontainer">
                            <label for="name">Name</label>
                            <div>
                                <input
                                    value={contactData.name}
                                    onChange={(e) => handleInputChange("name", e.target.value)}
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="First Last"
                                    required
                                />
                            </div>
                        </div>


                        <div class="formSubcontainer">
                            <label for="email">e-mail address</label>
                            <div>
                                <input
                                    value={contactData.email}
                                    onChange={(e) => handleInputChange("email", e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="sample@sample.com"
                                    required
                                />
                            </div>
                        </div>


                        <div class="formSubcontainer">
                            <label for="phone">Phone number</label>
                            <div>
                                <input
                                    value={contactData.phoneNumber}
                                    onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    placeholder="(123) 555-5555"
                                    required
                                />
                            </div>
                        </div>


                        <div class="formSubcontainer">
                            <label for="contactReason">Reason for contact</label>
                            <div>
                                <select
                                    id="contactReason"
                                    name="contactReason"
                                    value={contactData.reason}
                                    onChange={(e) => handleInputChange("reason", e.target.value)}
                                >
                                    <option value=""> </option>
                                    <option value="Employment Opportunity">Employment Opportunity</option>
                                    <option value="Networking">Networking</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>


                        <div class="formSubcontainer">
                            <label for="comments">Comments</label>
                            <div>
                                <textarea
                                    value={contactData.content}
                                    onChange={(e) => handleInputChange("content", e.target.value)}
                                    id="comments"
                                    name="comments"
                                >
                                </textarea>
                            </div>
                        </div>


                        <div class="formSubcontainer">
                            <label for="submit"></label>
                            <div>
                                <button
                                    id="submit"
                                    name="submit"
                                    onClick={validate}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default Contact;
