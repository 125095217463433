
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import About from './components/pages/About';
import Home from './components/pages/Home';
import Portfolio from './components/pages/Portfolio';
import Resume from "./components/pages/Resume";
import Contact from "./components/pages/Contact";

import AdminLogin from './components/pages/AdminLogin';
import AddUser from "./components/pages/AddUser";
import ContactEntries from "./components/pages/ContactEntries";

import Navigation from './components/pages/shared/Navigation';
import Footer from './components/pages/shared/Footer';

import ProtectedRoute from './components/ProtectedRoute';

function App() {

    return (
        <Router>

            <Navigation />

            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/contact' component={Contact} />
                <Route path='/about' component={About} />
                <Route path='/resume' component={Resume} />
                <Route path='/portfolio' component={Portfolio} />
                <Route path='/admin-login' component={AdminLogin} />
                <ProtectedRoute path='/contact-entries' component={ContactEntries} />
                <ProtectedRoute path='/add-user' component={AddUser} />
            </Switch>

            <Footer />

        </Router>
    );



}

export default App;
