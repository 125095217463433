import React, { useState, useEffect } from 'react';




function Resume() {
    return (
        <div className="App">

            <div className="container">

                <div className="bioContainer">
                    <p>Detail-oriented and systematic professional well-versed in Full Stack Development and Microsoft Office Suite. Successful at utilizing diverse resources and analytical strategies to make effective decisions about workforce needs. Collaborative and open team player adept at working with management to find and implement effective solutions.</p>
                </div>


                <div className="aboutContainer clearfix">

                    <div className="resumeBoxes clearfix">

                        {/*CONTACT DETAILS*/}
                        <div className="table resumeInfoTable">

                            <div className="tr rowFill">
                                <div className="td">
                                    CONTACT DETAILS
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">>>"}
                                </div>
                            </div>

                            <div className="table innerTable">

                                <div className="tr">
                                    <div className="td">
                                        ADDRESS:
                                    </div>
                                    <div className="td">
                                        Brampton, ON, L6T2W8
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        PHONE:
                                    </div>
                                    <div className="td">
                                        289 788 3701
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        E-MAIL:
                                    </div>
                                    <div className="td">
                                        ellison.steele@gmail.com
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="clear"></div>

                        {/*SKILLS*/}
                        <div className="table resumeInfoTable">

                            <div className="tr rowFill">
                                <div className="td">
                                    SKILLS
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">>>"}
                                </div>
                            </div>

                            <div className="table innerTable">

                                <div className="tr">
                                    <div className="td">
                                        Data Collection and Research
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        Agile Framework
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        Microsoft Office
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        Web development projects
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="clear"></div>

                        {/*CODING PROFICIENCY*/}
                        <div className="table resumeInfoTable">

                            <div className="tr rowFill">
                                <div className="td">
                                    CODING PROFICIENCY
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">>>"}
                                </div>
                            </div>

                            <div className="table innerTable">

                                <div className="tr">
                                    <div className="td">
                                        JavaScript
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        CSS
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        HTML
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        C++
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        Power BI
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="clear"></div>

                        {/*LANGUAGES*/}
                        <div className="table resumeInfoTable">

                            <div className="tr rowFill">
                                <div className="td">
                                    LANGUAGES
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">>>"}
                                </div>
                            </div>

                            <div className="table innerTable">

                                <div className="tr">
                                    <div className="td">
                                        English
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        French
                                    </div>
                                </div>

                                <div className="tr">
                                    <div className="td">
                                        Japanese
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    {/*/aboutBoxes*/}

                    <div className="resumeJobs clearfix" style={{ float: 'right' }}>
                        <div className="table resumeInfoTable">
                            <div className="tr rowFill">
                                <div className="td">
                                    WORK HISTORY
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">>>"}
                                </div>
                            </div>

                            <div className="tr rowFill">
                                <div className="td">
                                    WORKFORCE MANAGEMENT ANALYST
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    Currently under NDA, Windsor, ON
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    2020-08 - Current
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Worked with management to develop strategic and tactical plans to meet different requirements.
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Analyzed architecture design, scoping, implementation, testing and deployment needs to define project requirements.
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Created training materials for new employees to provide information about attendance guidelines.
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Identified computer hardware and network system issues, performing troubleshooting techniques for remediation.
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Facilitated best user experience through continuous support, training classes, webinars, improvements and communication of system changes.
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Implemented and managed wireless devices, multi-site network infrastructure, business intelligence developments.
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">"} Coordinated with management teams to plan, develop, align and execute strategies that would meet client's vision, mission and purpose.
                                </div>
                            </div>

                        </div>

                        <div className="table resumeInfoTable">
                            <div className="tr rowFill">
                                <div className="td">
                                    EDUCATION
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    {">>>"}
                                </div>
                            </div>

                            <div className="tr rowFill">
                                <div className="td">
                                    CERTIFICATE: FULL STACK WEB DEVELOPER
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    York University, Toronto, ON
                                </div>
                            </div>

                            <div className="tr">
                                <div className="td">
                                    2021-05 - Current
                                </div>
                            </div>

                        </div>

                    </div>
                    {/*/resumeJobs*/}


                </div>
                {/*/aboutContainer*/}


            </div>
            {/*/container*/}

        </div>
    );
}

export default Resume;
