import React from 'react';
import { useHistory } from 'react-router-dom';
import './../../App.css';

import ellisonThumbnail from './../../images/folderthumbnail.png';
import catThumbnail from './../../images/cat-denied-thumbnail.png';
import tarunThumbnail from './../../images/tarun-denied-thumbnail.png';

function Home() {
    const history = useHistory();

    function navigateAbout() {
        let path = `/about`;
        history.push(path);
    }

    function alertDenied() {
        alert("Access Denied!");
    }

    return (
        <div className="App">

            <div class="container clearfix">

                <div class="fileFolder" id="file1" onClick={navigateAbout}>
                    <div class="tabContainer">
                        <div class="fileTab"></div>
                        <div class="folderBackContainer">
                            <div class="folderSpacer"></div>
                            <div class="folderBack"></div>
                        </div>
                    </div>

                    <div class="folderContents">
                        <img src={ellisonThumbnail} alt="Ellison profile thumbnail" />

                        <span>
                            Ellison Steele
                            <p><b>Access Granted</b></p>
                        </span>
                    </div>

                </div>

                <div class="fileFolder denied" id="file2" onClick={alertDenied}>
                    <div class="tabContainer">
                        <div class="fileTab"></div>
                        <div class="folderBackContainer">
                            <div class="folderSpacer"></div>
                            <div class="folderBack"></div>
                        </div>
                    </div>

                    <div class="folderContents">
                        <img src={catThumbnail} alt="Artemis profile thumbnail" />

                        <span>
                            Access Denied
                        </span>
                    </div>

                </div>

                <div class="fileFolder denied" id="file3" onClick={alertDenied}>
                    <div class="tabContainer">
                        <div class="fileTab"></div>
                        <div class="folderBackContainer">
                            <div class="folderSpacer"></div>
                            <div class="folderBack"></div>
                        </div>
                    </div>

                    <div class="folderContents">
                        <img src={tarunThumbnail} alt="Tarun profile thumbnail" />

                        <span>
                            Access Denied
                        </span>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Home;
