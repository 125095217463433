
import photo from './../../images/folder-thumbnail-granted.png';

function About() {
  return (
    <div className="App">

      <div className="container">

        <div className="bioContainer">
          <p>EMPLOYEE BIO: “Accomplished Web Developer with years of managerial experience. Strong background in workforce
            management and real-time data analytics.</p>

          <p>LAST SEEN: Toronto, Ontario, Canada</p>

        </div>


        <div className="aboutContainer clearfix">

          <div className="aboutBoxes clearfix">

            {/*PHYSICAL CHARACTERISTICS*/}
            <div className="table infoTable">

              <div className="tr rowFill">
                <div className="td">
                  PHYSICAL CHARACTERISTICS
                </div>
              </div>

              <div className="tr">
                <div className="td">
                  {">>>"}
                </div>
              </div>

              <div className="table innerTable">

                <div className="tr">
                  <div className="td">
                    HAIR:
                  </div>
                  <div className="td">
                    Purple
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    EYE COLOUR:
                  </div>
                  <div className="td">
                    Blue
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    HEIGHT:
                  </div>
                  <div className="td">
                    163 cm
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    BUILD:
                  </div>
                  <div className="td">
                    Compact
                  </div>
                </div>

              </div>

            </div>

            {/*FUNCTION*/}
            <div className="table infoTable">

              <div className="tr rowFill">
                <div className="td">
                  FUNCTION INFORMATION
                </div>
              </div>

              <div className="tr">
                <div className="td">
                  {">>>"}
                </div>
              </div>

              <div className="table innerTable">

                <div className="tr">
                  <div className="td">
                    MAIN FUNCTION:
                  </div>
                  <div className="td">
                    Full Stack Developer
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    SECONDARY FUNCTIONS:
                  </div>
                  <div className="td">
                    Video Game Design, Gaming, Digital Art, Reading, Cycling, Baking
                  </div>
                </div>

              </div>

            </div>

            <div className="clear"></div>

            {/*KNOWN ASSOCIATES*/}
            <div className="table infoTable">

              <div className="tr rowFill">
                <div className="td">
                  KNOWN ASSOCIATES
                </div>
              </div>

              <div className="tr">
                <div className="td">
                  {">>>"}
                </div>
              </div>

              <div className="table innerTable">

                <div className="tr">
                  <div className="td">
                    NAME:
                  </div>
                  <div className="td">
                    Artemis, aka: Cat
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    FUR:
                  </div>
                  <div className="td">
                    Black
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    EYE COLOUR:
                  </div>
                  <div className="td">
                    Yellow
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    BUILD:
                  </div>
                  <div className="td">
                    Hefty Chonker
                  </div>
                </div>

              </div>

            </div>

            {/*TRAINING*/}
            <div className="table infoTable">

              <div className="tr rowFill">
                <div className="td">
                  TRAINING
                </div>
              </div>

              <div className="tr">
                <div className="td">
                  {">>>"}
                </div>
              </div>

              <div className="table innerTable">

                <div className="tr">
                  <div className="td">
                    MANAGERIAL:
                  </div>
                  <div className="td">
                    Real-time data analytics, Workforce management
                  </div>
                </div>

                <div className="tr">
                  <div className="td">
                    SOFTWARE:
                  </div>
                  <div className="td">
                    JavaScript, CSS, HTML, Microsoft Office Suite, Adobe Photoshop, Blender
                  </div>
                </div>

              </div>

            </div>

          </div>
          {/*/aboutBoxes*/}

          <div className="clearfix" style={{ float: 'right' }}>
            <div className="table aboutPhoto">
              <div className="tr rowFill">
                <div className="td">
                  FILE PHOTO
                </div>
              </div>

              <div className="tr">
                <div className="td">
                  {">>>"}
                </div>
              </div>

              <div className="tr">
                <div className="td">
                  <img src={photo} />
                </div>
              </div>

            </div>

          </div>
          {/*/photo*/}


        </div>
        {/*/aboutContainer*/}


      </div>
      {/*/container*/}

    </div>
  );
}

export default About;
