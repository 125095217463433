import React from 'react';

function AddUser() {
    return (
        <div className="App">

            <div className="container">

                <div className="resumeContainer">

                </div>

            </div>
        </div>
    );
}

export default AddUser;
