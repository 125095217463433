import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouteLink } from 'react-router-dom';


export default function App() {
    const location = useLocation();
    return (

        <Navbar>
            {location.pathname === '/' ? (
                <h1>WELCOME TO <br /> THE STELLIS CORP EMPLOYEE DATABASE</h1>
            ) : (
                <h1>STELLIS CORP EMPLOYEE DATABASE</h1>
            )}

            {location.pathname === '/contact' || location.pathname === '/' || location.pathname === '/contact-entries' || location.pathname === '/admin-login' ? (
                null
            ) : (
                <h2>Ellison Steele</h2>
            )}

            <div className="navContainer">
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink tag={RouteLink} to="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RouteLink} to="/about">About</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RouteLink} to="/resume">Resume</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RouteLink} to="/portfolio">Portfolio</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RouteLink} to="/contact">Contact</NavLink>
                    </NavItem>
                </Nav>
            </div>

        </Navbar >

    )
}

