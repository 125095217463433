import React from 'react';
import './../../App.js';

import brokenFile from './../../images/broken-file.png';



function Portfolio() {
    return (
        <div className="container">

            <div className="container clearfix">

                <div className="fileFolder denied">
                    <div className="tabContainer">
                        <div className="fileTab"></div>
                        <div className="folderBackContainer">
                            <div className="folderSpacer"></div>
                            <div className="folderBack"></div>
                        </div>
                    </div>

                    <div className="folderContents">
                        <img src={brokenFile} alt="Broken file" />

                        <span>
                            Coming Soon
                        </span>
                    </div>

                </div>

                <div className="fileFolder denied">
                    <div className="tabContainer">
                        <div className="fileTab"></div>
                        <div className="folderBackContainer">
                            <div className="folderSpacer"></div>
                            <div className="folderBack"></div>
                        </div>
                    </div>

                    <div className="folderContents">
                        <img src={brokenFile} alt="Broken file" />

                        <span>
                            Coming Soon
                        </span>
                    </div>

                </div>


                <div className="fileFolder denied">
                    <div className="tabContainer">
                        <div className="fileTab"></div>
                        <div className="folderBackContainer">
                            <div className="folderSpacer"></div>
                            <div className="folderBack"></div>
                        </div>
                    </div>

                    <div className="folderContents">
                        <img src={brokenFile} alt="Broken file" />

                        <span>
                            Coming Soon
                        </span>
                    </div>

                </div>

            </div>
        </div>
    );
}



export default Portfolio;
